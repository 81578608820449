import axios from 'axios';

function getCsrfTokenFromCookies() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key === 'csrftoken') {
            return value;
        }
    }
    return null; // Return null if the token is not found
}


let Server = `${window.origin}/api`;

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const api = axios.create({
    baseURL: Server,  
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});


api.interceptors.request.use((config) => {
    const csrfToken = getCsrfTokenFromCookies();
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});
export default api;

