import React, { useState } from 'react';
import api from '../api';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [rememberMe, setrememberMe] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setError('جاري الدخول ....');
      // const csrftoken = await api.get('/csrf');
      // console.log(csrftoken);
      const response = await api.post('/token/access', { username, password });
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      const response_session = await api.post('/SystemPackageBalance/');
      localStorage.setItem('permission', response_session.data);
      onLogin(response_session.data);
    } catch (e) {
      console.log(e);
      setError(`${e.response.data.non_field_errors || (e.response.data.username && ' (اسم المستخدم) ' + e.response.data.username) || (e.response.data.password && ' (كلمة المرور) ' + e.response.data.password) || e.response.data.detail || e.response.data || e.message}`);
    }
  };

  return (
    <main className="main-content  mt-0">
      <div className="page-header align-items-start min-vh-100 " >
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-9 mx-auto">
              <div className="card z-index-2 mt-4">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                    <i className="material-symbols-rounded opacity-10">login</i>
                  </div>
                  <div className="d-block d-md-flex">
                    <div className="ms-auto me-2 mt-2">
                      <h4 className="mb-0">تسجيل الدخول</h4>
                    </div>

                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleLogin} className="text-start" autoComplete="off" >
                    <div className="input-group input-group-outline">
                      <label className='form-label '>اسم المستخدم</label>
                      <input type="text" id="username"
                        name="fake-username-field"
                        autoComplete="off"
                        onChange={(e) => setUsername(e.target.value)} className="form-control" />
                    </div>
                    <br />
                    <div className="input-group input-group-outline">
                      <label className='form-label '>كلمة المرور</label>
                      <input type="password" id="password"
                        name="fake-password-field"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control" />
                    </div>
                    <br />
                    <div className=" d-flex align-items-center ">
                      <div className="text-center">
                        <button type="submit" className="btn bg-gradient-dark w-auto mt-3 mb-0  mb-0 " onClick={(e) => { let toast = document.getElementById('dangerToast').classList; toast.replace('hide', 'show'); setTimeout((e) => { toast.replace('show', 'hide') }, 7000); setError(null); }}>دخول</button>
                      </div>
                      {/* <div className="form-check form-switch d-flex align-items-left me-auto">
                        <label className="form-check-label mb-0 ms-3 font-weight-bold" >تذكرني </label>
                        <input className="form-check-input" type="checkbox" id="rememberMe" onChange={(e) => setrememberMe(e.target.value)} />

                      </div> */}
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer position-absolute bottom-2 py-2 w-100">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-12 col-md-6 my-auto">
                <div className="copyright text-center text-sm text-white text-lg-start">
                  © <script>
                    document.write(new Date().getFullYear())
                  </script> ITS Inc

                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="position-fixed bottom-1 end-1 z-index-2">
        <div className="toast fade hide p-2 mt-2 " role="alert" aria-live="assertive" id="dangerToast" aria-atomic="true">
          <div className="toast-body font-weight-bold"><i className="fas fa-times text-md cursor-pointer ms-2" data-bs-dismiss="toast" aria-label="Close"></i>  {error}</div>

        </div>
      </div>
    </main>
  );
}

export default Login;
