import React, { useEffect } from 'react';
import api from '../api';

const UserSms = () => {
  useEffect(() => {
  
    window.$('#gridUserSms').dxDataGrid({
      dataSource: new window.DevExpress.data.CustomStore({
          key: 'pk',
          load: async () => {
            try {
              return await api.get(`/UserSms/`);
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message);
              return e;
            }
          },
          insert: async (values) => {
            try {
              return await api.post('/UserSms/', JSON.stringify(values));
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message);
              return e;
            }
          },
          update: async (key, values) => {
            try {
              return await api.put(`/UserSms/${key}/`, JSON.stringify(values));
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message);
              return e;
            }
            
          },
          remove: async (key) => {
            try {
              return await api.delete(`/UserSms/${key}/`);
            } catch (e) {
              e.message = (e.response.data.messages && e.response.data.messages[0].message)||e.response.data.detail||e.response.data.result||e.response.data||e.message;
              window.show_message(e.message);
              return e;
            }
          },
      }),
      columns: [
          { dataField: 'pk', caption: 'رقم المستخدم', dataType: 'number', allowEditing: false,formItem: {visible: false,} },
          { dataField: 'username', caption: 'اسم المستخدم', dataType: 'string',validationRules: [{ type: 'required' }], },
          { dataField: 'password', caption: 'كلمة المرور', dataType: 'string',validationRules: [{ type: 'required' }],visible:false },
          { dataField: 'first_name', caption: 'الاسم الاول', dataType: 'string' },
          { dataField: 'last_name', caption: 'الاسم الاخير', dataType: 'string' },
          { dataField: 'is_active', caption: 'فعال', dataType: 'boolean',validationRules: [{ type: 'required' }], },
      ],
      keyExpr: 'pk',
      // export: {
      //   enabled: true,
      //   formats: ['pdf','excel'],
      //   allowExportSelectedData: true,
      // },
      filterPanel: { visible: true },
      headerFilter: { visible: true },
      filterBuilder: {
        allowHierarchicalFields: true,
      },
      filterBuilderPopup: {
        position: {
          of: window, at: 'top', my: 'top', offset: { y: 10 },
        },
      },
      filterRow: {
        visible: true,
        applyFilter: 'auto',
      },
      searchPanel: {
        visible: true,
        placeholder: 'Search...',
      },
      columnAutoWidth: true,
      showRowLines: true,
      showBorders: true,
      columnChooser: {
        enabled: true,
        mode: 'select',
        search: {
          enabled: true,
          editorOptions: { placeholder: 'Search column' },
        },
        selection: {
          recursive: true,
          selectByClick: true,
          allowSelectAll: true,
        },
      },
      
    allowColumnResizing: true,
    showColumnLines: true,
    rowAlternationEnabled: true,
      editing: {
          mode: 'form', // Editing UI: 'popup', 'form', or 'row'
          allowAdding: true,
          allowUpdating: true,
          allowDeleting: true,
      }, // Enable header filter
      paging: { pageSize: 5 },          // Enable paging with page size
      pager: {   
        visible: true,                       // Pager configuration
          showPageSizeSelector: true,
          allowedPageSizes: [5, 10, 20,'all'],
          showInfo: true,
      },
      // remoteOperations: false,
      groupPanel: { visible: true },
      grouping: {
        autoExpandAll: false,
      },
      allowColumnReordering: true,
      
  });
  
  
  }, []); // Runs once after component mounts

  return (
 
       <div className="col-lg-12 position-relative z-index-2">
        
       <div className="ms-3 p-1 my-2">
         <h3 className="mb-0 h4 font-weight-bolder">المستخدمين</h3>
       </div>
       
       <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card z-index-2 mt-4">
            <div className="card-header p-3 pt-2">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start">
                <i className="material-symbols-rounded opacity-10">group</i>
              </div>
              <div className="d-block d-md-flex">
                <div className="ms-auto">
                  <h6 className="mb-0">جدول المستخدمين</h6>
                </div>
                
              </div>
            </div>
            <div className="card-body p-3 pt-0">
              <div className="chart">
              <div id="gridUserSms"></div>
              </div>
            </div>
          </div>
        </div>
      
       </div>
     </div>
 
  );
};

export default UserSms;
